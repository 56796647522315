import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HighlightTermComponent} from '../highlightTerm/highlight-term.component';

@Component({
    selector: 'app-select-option',
    templateUrl: './select-option.component.html',
    standalone: true,
    imports: [
        CommonModule,
        HighlightTermComponent,
    ],
})
export class SelectOptionComponent {
    @Input() option;
    @Input() searchTerm: string;
    @Input() highlightSearchTerm = true;
    @Input() bindValue = 'id';
    @Input() bindLabel = 'name';
    @Input() bindCode = null;
    @Input() bindSameAs = 'same_as';
    isPrimitive = false;

    ngOnChanges() {
        this.isPrimitive = ['number', 'string'].includes(typeof this.option);
    }
}
