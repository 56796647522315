<ng-container *ngIf="!isPrimitive; else primitiveTmp">
    <div class="d-flex">
        <app-highlight-term class="mr-auto" [text]="option[bindLabel]" [term]="highlightSearchTerm && searchTerm"></app-highlight-term>
        <span *ngIf="bindCode && option[bindCode]" class="ml-2"><app-highlight-term class="small text-muted no-line-break" [text]="option[bindCode]" [term]="highlightSearchTerm && searchTerm"></app-highlight-term></span>
    </div>
    <div class="small text-muted" *ngIf="option[bindSameAs]">
        <app-highlight-term [text]="option[bindSameAs]" [term]="highlightSearchTerm && searchTerm"></app-highlight-term>
    </div>
</ng-container>
<ng-template #primitiveTmp>
    {{option}}
</ng-template>
